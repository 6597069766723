export function onClientEntry() {
  window.addEventListener('load', () => {
    document.body.classList.remove('no-js')
  })

  const doneResizing = () => {
    document.body.classList.remove('no-transition')
  }

  let resizeId
  window.addEventListener('resize', () => {
    document.body.classList.add('no-transition')
    clearTimeout(resizeId)
    resizeId = setTimeout(doneResizing, 500)
  })
}
